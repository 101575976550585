import * as React from "react"
import { AiOutlineArrowRight } from "react-icons/ai"
import useLocalizationKeys from "../components/localization-utils/useLocalizationKeys"
import useTranslation from "../components/localization-utils/useTranslation"
// Components
import LocalizedLink from "../components/links/localizedLink"
import SEO from "../components/seo"

// Homepage of the site dev.tinkermode.com/
const IndexPage: React.FC = (): JSX.Element => {
  const { homepage, SEO: s } = useLocalizationKeys()

  return (
    <>
      <SEO title={useTranslation(s.homepage.title)} description={useTranslation(s.homepage.description)} />
      <div id="homepage-header">
        <img
          src="/images/homepage/person-study.svg"
          alt="Cartoon - Person Working"
        />
        <h1>
          <span>{useTranslation(homepage.develop)}</span>
          <span>{useTranslation(homepage.withMode)}</span>
        </h1>
      </div>
      <div id="homepage-body-wrapper">
        <section id="introduction-section">
          <p>{useTranslation(homepage.introLineOne)}</p>
          <p>{useTranslation(homepage.introLineTwo)}</p>
        </section>
        <section id="buttons-section">
          <LocalizedLink to="/introduction-mode/what-is-mode" className="homepage-button">
            <p>{useTranslation(homepage.buttonTextOne)}</p>
            <AiOutlineArrowRight />
          </LocalizedLink>
          <LocalizedLink to="/platform/api-info/01-intro" className="homepage-button">
            <p>{useTranslation(homepage.buttonTextThree)}</p>
            <AiOutlineArrowRight />
          </LocalizedLink>
        </section>
        <hr />
        <section id="console-section">
          <div>
            <h2>{useTranslation(homepage.consoleIntroduction)}</h2>
            <p>{useTranslation(homepage.consoleDescription)}</p>
            <a
              href="https://console.tinkermode.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button">
                {useTranslation(homepage.goToConsoleText)}
              </button>
            </a>
          </div>
          <img
            src="/images/homepage/console-screenshot.png"
            alt="Screenshot - Console Homepage"
            className="screenshot-console"
          />
        </section>
        <hr />
        <section id="contact-us-section">
          <h3>{useTranslation(homepage.footerTitle)}</h3>
          <p>
            <LocalizedLink to="/contact-us" className="emphasis-text">
              {useTranslation(homepage.contactUsLinkText)}
            </LocalizedLink>{" "}
            {useTranslation(homepage.contactUsText)}
          </p>
          <p> {`© ${new Date().getFullYear()} MODE, Inc.`}</p>
        </section>
      </div>
    </>
  )
}

export default IndexPage
